import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // Override la méthode format pour retourner une string
  override format(date: Date): string {
    let day;
    if(date.getTimezoneOffset() <= 0 || (date.getTimezoneOffset() > 0 && date.getHours() === 0)) {
      day = (date.getDate()).toString().padStart(2, '0');
    } 
    else if ((date.getDate() !== date.getUTCDate()) && (date.getTimezoneOffset() > 0 && date.getHours() === 0)) {
      day = (date.getDate()).toString().padStart(2, '0');
    } 
    else {
      day = (date.getUTCDate()).toString().padStart(2, '0');
    }
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Override la méthode pour commencer le lundi.
  override getFirstDayOfWeek(): number {
    return 1;
  }

  // Override parse pour convertir une string en date
  override parse(value: any): Date | null {
    if (typeof value === 'string' && value.indexOf('/') > -1) {
      const [day, month, year] = value.split('/').map(Number);
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      const offsetInHours = Math.abs(timezoneOffsetInMinutes) / 60;
      const date = new Date(year, month - 1, day, offsetInHours > 12 ? 12 : offsetInHours);
      date.setHours(0);
      return date;
    }
    if (typeof value === 'string' && value.indexOf('-') > -1) {
      const [year, month, day] = value.split('-').map(Number);
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      const offsetInHours = (-timezoneOffsetInMinutes) / 60;
      const date = new Date(year, month - 1, day, offsetInHours-1);
      return date;
    }
    return null;
  }
}
